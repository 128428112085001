import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';
import Swiper, { Navigation, Pagination } from 'swiper';
import bodymovin from 'lottie-web';
import "@lottiefiles/lottie-player";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

Swiper.use([Navigation, Pagination]);

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    // gated content block JS (download assets when the user submits the form
    const myForms = document.querySelectorAll("[id^='gatedForm']");
    //  var windowReference = window.open();

    myForms.forEach(myForm => {

        myForm.addEventListener('submit', function(e) {

            const blockId = myForm.id.replace('gatedForm-', '')
            let assetsToDownload = document.querySelectorAll(`[data-asset-block="${blockId}"]`)
            assetsToDownload.forEach(assetToDownload => {
                console.log(myForm)

                let checkBox = myForm.querySelector('input[type="checkbox"]')

                if (checkBox.checked) {
                    assetToDownload.click();
                }

            })

        })

    })

    // Setting the height using js for mega menu to solve the padding issue
    let navLinks = document.querySelectorAll('header .nav-link')
    
    navLinks.forEach(navlink => {
        navlink.addEventListener('mouseover', () => {
            let subNavs = navlink.querySelectorAll('.sub-nav')
            if(subNavs) {
                subNavs.forEach(subnav => {
                    let subNavGrandchildren = subnav.querySelectorAll('.sub-nav-grandchild'),
                    subNavHeight,
                    subNavGrandchildrenHeights = []
                    
                    if(subNavGrandchildren) {
                        subNavGrandchildren.forEach(nav => {
                            subNavGrandchildrenHeights.push(nav.getBoundingClientRect().height)
                        })
                        subNavHeight = Math.max(...subNavGrandchildrenHeights)
                        subNavGrandchildren.forEach(nav => {
                            nav.style.height = `${subNavHeight}px`
                        })
                    }
                    subnav.style.height = `${subNavHeight}px`
                })
            }
        })
    })

    // Lottie animations
    // Rendering animation once all the green bts inserted into dom
    let angleLottie = document.querySelector('#angle-lottie')
    let contentLinks = document.querySelectorAll('.content-link')
    let contentLinksOpacityValue = 0

    if(contentLinks.length > 0) {
        let contentLinksLength = contentLinks.length - 1
        let contentLinksInterval = setInterval(() => {
            if(contentLinksOpacityValue < 1) {
                contentLinksOpacityValue = window.getComputedStyle(contentLinks[contentLinksLength]).opacity
            } else {
                clearInterval(contentLinksInterval)
                if(angleLottie) {
                    angleLottie.load('/lottie/Angle.json')
                }
            }
        }, 100)
    }

    let windowHeight

    function init() {
        windowHeight = window.innerHeight;
    }

    function checkPosition(elem, animation) {
        var positionFromTopLoop = elem.getBoundingClientRect().top;
        if (positionFromTopLoop - windowHeight <= 0) {
            setTimeout(function(){ animation.play(); }, 1000);
        }
    }

    let footerStarLottie = document.querySelector('#footerStar-lottie')
    const animationFooterStar = bodymovin.loadAnimation({
        container: footerStarLottie,
        path: '/lottie/FooterStar.json',
        renderer: 'svg',
        loop: false,
        autoplay: false,
    });

    let testimonialHexLottie = document.querySelector('#testimonial-hex-lottie')
    const animationTestimonialHexLottie = bodymovin.loadAnimation({
        container: testimonialHexLottie,
        path: '/lottie/Hexagon.json',
        renderer: 'svg',
        loop: false,
        autoplay: false,
    });
    
    let lateralLottie = document.querySelector('#lateral-lottie')
    const animationLateralLottie = bodymovin.loadAnimation({
        container: lateralLottie,
        path: '/lottie/Lateral.json',
        renderer: 'svg',
        loop: false,
        autoplay: false,
    });

    let pyramidImageFullLottie = document.querySelector('#pyramid-lottie-image-full')
    const animationPyramidImageFullLottie = bodymovin.loadAnimation({
        container: pyramidImageFullLottie,
        path: '/lottie/Pyramid.json',
        renderer: 'svg',
        loop: false,
        autoplay: false,
    });

    let pyramidImageLottie = document.querySelector('#pyramid-lottie-image')
    const animationPyramidImageLottie = bodymovin.loadAnimation({
        container: pyramidImageLottie,
        path: '/lottie/Pyramid.json',
        renderer: 'svg',
        loop: false,
        autoplay: false,
    });

    if(footerStarLottie || testimonialHexLottie || lateralLottie || pyramidImageFullLottie || pyramidImageLottie) {
        init()
        window.addEventListener('resize', init);
        if(footerStarLottie) {
            checkPosition(footerStarLottie, animationFooterStar)
        }
        if(testimonialHexLottie) {
            checkPosition(testimonialHexLottie, animationTestimonialHexLottie)
        }
        if(lateralLottie) {
            checkPosition(lateralLottie, animationLateralLottie)
        }
        if(pyramidImageFullLottie) {
            checkPosition(pyramidImageFullLottie, animationPyramidImageFullLottie)
        }
        if(pyramidImageLottie) {
            checkPosition(pyramidImageLottie, animationPyramidImageLottie)
        }
        window.addEventListener('scroll', () => {
            if(footerStarLottie) {
                checkPosition(footerStarLottie, animationFooterStar)
            }
            if(testimonialHexLottie) {
                checkPosition(testimonialHexLottie, animationTestimonialHexLottie)
            }
            if(lateralLottie) {
                checkPosition(lateralLottie, animationLateralLottie)
            }
            if(pyramidImageFullLottie) {
                checkPosition(pyramidImageFullLottie, animationPyramidImageFullLottie)
            }
            if(pyramidImageLottie) {
                checkPosition(pyramidImageLottie, animationPyramidImageLottie)
            }
        })
    }

    // Adding class to the input element to change the background color to white
    const inputs = document.querySelectorAll('input[type=text],input[type=email], input[type=number], select, textarea')

    if(inputs) {
        inputs.forEach(input => {
            
            input.addEventListener('keydown', (e) => {
                if (e.currentTarget.value.length > 0) {
                    input.classList.add('input-filled')
                } else {
                    input.classList.remove('input-filled')
                }
            })
            input.addEventListener('change', (e) => {
                if (e.currentTarget.value) {
                    input.classList.add('input-filled')
                } else {
                    input.classList.remove('input-filled')
                }
            })
        })
    }

    gsap.set(".content-fade-slide", {opacity: 0, x:-20});

    ScrollTrigger.batch(".content-fade-slide", {
        onEnter: (elements, triggers) => {
            gsap.to(elements, {duration: 0.2, opacity: 1, x: 0, stagger: 0.25});
        },
        start: "20px 85%",
        // markers: true
    });

    gsap.set(".content-fade", {opacity: 0, y:-10});

    ScrollTrigger.batch(".content-fade", {
        onEnter: (elements, triggers) => {
            gsap.to(elements, {duration: 0.2, opacity: 1, y: 0, stagger: 0.25});
        },
        start: "20px 85%",
        // markers: true
    });

    

    // Change path svg color on hover services
    const services = document.querySelectorAll('.service')
    if(services) {
        services.forEach(service => {
            service.addEventListener('mouseover', () => {
                service.querySelector('#svg').contentDocument.querySelector('path').setAttribute('fill', '#002E6E')
            })
            service.addEventListener('mouseout', () => {
                service.querySelector('#svg').contentDocument.querySelector('path').setAttribute('fill', '#8395A1')
            })
        })
    }

    // SWIPER
    const swiper = new Swiper('.swiper-container', {

        grabCursor: 'true',
        loop: false,
        slidesPerView: 1,
        spaceBetween: 12,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }

    });

    // Mobile menu init // Sync search and menu open
    const menu = new MmenuLight( document.querySelector( "#mobile-menu" ), "(max-width: 1280px)" );
    const navigator = menu.navigation();
    const drawer = menu.offcanvas();

    // Open Menu
    const navTrigger = document.querySelector('.js-open-nav');
    const projectWrapper = document.getElementById('app');

    // Toggle Menu
    bindEvent(navTrigger, "click", function() {
        this.classList.toggle('is-active');
        projectWrapper.classList.toggle('js-is-open');

        if (this.classList.contains('is-active')) {
            drawer.open();
        } else {
            drawer.close();
        }

    });

    // Mobile navigation
    const navTriggers = document.querySelectorAll('.js-nav-btn'),
    panelTriggers = document.querySelectorAll('.js-panel-btn'),
    mobNav = document.querySelector('.mobile'),
    mobNavOverlay = document.querySelector('.mobile-overlay');

    if(navTriggers){
        navTriggers.forEach(function(navTrigger) {
            bindEvent(navTrigger, "click", function() {
                event.preventDefault();
                document.body.classList.toggle('modal-open');
                navTrigger.classList.toggle('nav-icon--active');
                mobNav.classList.add('mobile--transition');
                mobNav.classList.toggle('mobile--active');
                navTrigger.setAttribute('aria-expanded', navTrigger.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }, false);
        });
    }

    if (panelTriggers) {
        for (let i = 0, l = panelTriggers.length; i < l; i += 1) {
            panelTriggers[i].addEventListener('click', (event) => {
                event.preventDefault();
                const panelId = event.currentTarget.dataset.panelId;
                const panel = document.getElementById(`panel-${panelId}`);
                panel.classList.toggle("mobile__panel--active");
            });
        }
    }


    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target;
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
